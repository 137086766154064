import { getUserByInviteToken } from '@/services/auth'
import bus from '@/bus'

export default {
  props: ['token'],

  data() {
    return {
      error: false
    }
  },

  metaInfo() {
    return {
      title: this.$t('auth.acceptInvite.pageTitle')
    }
  },

  created() {
    this.acceptInvite()
  },

  methods: {
    acceptInvite() {
      getUserByInviteToken(this.token).then(response => {
        this.setInvitedUser(response)
        this.registerClient()
      }).catch(() => {
        this.error = true
      })
    },

    registerClient() {
      bus.$emit('registerRequested', 'client')
    },

    ...mapActions('auth', [
      'setInvitedUser'
    ])
  }
}